<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      baseImageHeigthUrl: BASE_IMAGE_URL,
      options: [],
      form: {
        status: true,
        content_title: '',
        timerMin: '00',
        timerSec: '00',
        image: '',
        imageHeigth: '',
        inputtag: [],
        is_video: false,
        // is_paid: false,
        video: '',
        videoedit: '',
        videoHeigth: '',
        short_description: '',
        is_send: false,
        notification_title: '',
        notification_text: '',
        type: 'free',
        feat: false,
      },

      optionsStatus: [
        { value: true, text: 'show' },
        { value: false, text: 'hide' },
      ],
      optionsType: [
        { value: true, text: 'Paid' },
        { value: false, text: 'Free' },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
      minutes: [
        { value: '00', text: '00'},
        { value: '01', text: '01'},
        { value: '02', text: '02'},
        { value: '03', text: '03'},
        { value: '04', text: '04'},
        { value: '05', text: '05'},
        { value: '06', text: '06'},
        { value: '07', text: '07'},
        { value: '08', text: '08'},
        { value: '09', text: '09'},
        { value: '10', text: '10'},

        { value: '11', text: '11'},
        { value: '12', text: '12'},
        { value: '13', text: '13'},
        { value: '14', text: '14'},
        { value: '15', text: '15'},
        { value: '16', text: '16'},
        { value: '17', text: '17'},
        { value: '18', text: '18'},
        { value: '19', text: '19'},        
        { value: '20', text: '20'},

        { value: '21', text: '21'},
        { value: '22', text: '22'},
        { value: '23', text: '23'},
        { value: '24', text: '24'},
        { value: '25', text: '25'},
        { value: '26', text: '26'},
        { value: '27', text: '27'},
        { value: '28', text: '28'},
        { value: '29', text: '29'},
        { value: '30', text: '30'},

        { value: '31', text: '31'},
        { value: '32', text: '32'},
        { value: '33', text: '33'},
        { value: '34', text: '34'},
        { value: '35', text: '35'},
        { value: '36', text: '36'},
        { value: '37', text: '37'},
        { value: '38', text: '38'},
        { value: '39', text: '39'},
        { value: '40', text: '40'},

        { value: '41', text: '41'},
        { value: '42', text: '42'},
        { value: '43', text: '43'},
        { value: '44', text: '44'},
        { value: '45', text: '45'},
        { value: '46', text: '46'},
        { value: '47', text: '47'},
        { value: '48', text: '48'},
        { value: '49', text: '49'},
        { value: '50', text: '50'},

        { value: '51', text: '51'},
        { value: '52', text: '52'},
        { value: '53', text: '53'},
        { value: '54', text: '54'},
        { value: '55', text: '55'},
        { value: '56', text: '56'},
        { value: '57', text: '57'},
        { value: '58', text: '58'},
        { value: '59', text: '59'},
      ],
      second: [
        { value: '00', text: '00'},
        { value: '01', text: '01'},
        { value: '02', text: '02'},
        { value: '03', text: '03'},
        { value: '04', text: '04'},
        { value: '05', text: '05'},
        { value: '06', text: '06'},
        { value: '07', text: '07'},
        { value: '08', text: '08'},
        { value: '09', text: '09'},
        { value: '10', text: '10'},

        { value: '11', text: '11'},
        { value: '12', text: '12'},
        { value: '13', text: '13'},
        { value: '14', text: '14'},
        { value: '15', text: '15'},
        { value: '16', text: '16'},
        { value: '17', text: '17'},
        { value: '18', text: '18'},
        { value: '19', text: '19'},        
        { value: '20', text: '20'},

        { value: '21', text: '21'},
        { value: '22', text: '22'},
        { value: '23', text: '23'},
        { value: '24', text: '24'},
        { value: '25', text: '25'},
        { value: '26', text: '26'},
        { value: '27', text: '27'},
        { value: '28', text: '28'},
        { value: '29', text: '29'},
        { value: '30', text: '30'},

        { value: '31', text: '31'},
        { value: '32', text: '32'},
        { value: '33', text: '33'},
        { value: '34', text: '34'},
        { value: '35', text: '35'},
        { value: '36', text: '36'},
        { value: '37', text: '37'},
        { value: '38', text: '38'},
        { value: '39', text: '39'},
        { value: '40', text: '40'},

        { value: '41', text: '41'},
        { value: '42', text: '42'},
        { value: '43', text: '43'},
        { value: '44', text: '44'},
        { value: '45', text: '45'},
        { value: '46', text: '46'},
        { value: '47', text: '47'},
        { value: '48', text: '48'},
        { value: '49', text: '49'},
        { value: '50', text: '50'},

        { value: '51', text: '51'},
        { value: '52', text: '52'},
        { value: '53', text: '53'},
        { value: '54', text: '54'},
        { value: '55', text: '55'},
        { value: '56', text: '56'},
        { value: '57', text: '57'},
        { value: '58', text: '58'},
        { value: '59', text: '59'},             

      ],      
    }
  },
  validations: {
    form: {
      content_title: {
        required,
      },
      timer: {
        required,
      },
      image: {
        required,
      },
      imageHeigth: {
        required,
      }
    },
  },
  mounted() {
    this.initialUpdateItem()
    this.form.category = this.$route.query.categoryID || ''
    // console.log(this.$route.query)
    // console.log(this);
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
    vfileAdded_high(file) {
      this.form.imageHeigth = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded_high(file) {
      this.ismanuallyadd = true
    },

    async handleClickfeatContent(id) {
      // console.log(id._id);
      console.log(id.feat)
      // this.form.feat = id.feat
      console.log('feat', this.form.feat)
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async formSubmit() {
      if (this.isEdit == false) {
        if (!this.form.image) {
          this.$toast.error('Image field must not be empty ')
        }

        let id = []
        this.form.inputtag.map((contenttag) => {
          id.push(contenttag._id)
        })
        this.doAjax()

        let body = {
          content_title: this.form.content_title,
          timer: this.form.timerMin + ':' + this.form.timerSec,
          short_description: this.form.short_description,
          hashtag: id,
          status: this.form.status,
          is_paid: this.form.is_paid,
          type: this.form.type,
          feat: this.form.feat,
        }
        if (this.$route.query.categoryID) {
          body.category = this.$route.query.categoryID
        }

        console.log(body, 'body jaaaa')

        if (!this.isEdit && this.form.is_send) {
          body.is_send = this.form.is_send
          body.notification_title = this.form.notification_title
          body.notification_text = this.form.notification_text
        }

        const data = new FormData()

        if (!this.ismanuallyadd) {
          let img = this.form.image
          data.append('image', img)
          let img_heigth = this.form.imageHeigth
          data.append('imageHeigth', img_heigth)
        }

        data.append('data', JSON.stringify(body))
        const queryId = this.$route.query.id
        const request = !this.isEdit
          ? axios.post('/api/trainingidea/createWorkouts', data)
          : axios.patch(
              `/api/trainingidea/updateWorkouts/${queryId}`,
              data
            )

        await request
          .then(async (result) => {
            let id

            if (this.isEdit) {
              id = queryId
            } else {
              id = result.data._id
            }

            // await axios.post('/api/content/onUpdateContentText', {
            //   id,
            //   timer: this.form.timer,
            // })

            this.onCancel()
            this.$toast.success('Saved successfully')
            this.$router.go(-1)
            // this.$router.push('/contents/contentlist')
          })
          .catch((err) => {
            this.onCancel()
            this.$toast.error(err.response.data.error.message)
          })
      } else {
        // console.log(this.isEdit);
        // console.log('video', this.form.video)
        // console.log(this.form.is_video)
        if (!this.form.is_video) {
          if (!this.form.image) {
            this.$toast.error('Image field must not be empty ')
          }
        }

        let id = []
        this.form.inputtag.map((contenttag) => {
          id.push(contenttag._id)
        })
        this.doAjax()

        let body = {
          content_title: this.form.content_title,
          timer: this.form.timerMin + ':' + this.form.timerSec,
          short_description: this.form.short_description,
          hashtag: id,
          status: this.form.status,
          is_paid: this.form.is_paid,
          is_video: this.form.is_video,
          type: this.form.type,
          feat: this.form.feat,
        }
        if (this.$route.query.categoryID) {
          body.category = this.$route.query.categoryID
        }

        console.log(body, 'body jaaaa')

        if (!this.isEdit && this.form.is_send) {
          body.is_send = this.form.is_send
          body.notification_title = this.form.notification_title
          body.notification_text = this.form.notification_text
        }   

        const data = new FormData()

        if (!this.ismanuallyadd) {
          let img = this.form.image
          data.append('image', img)
          let img_heigth = this.form.imageHeigth
          data.append('imageHeigth', img_heigth)
        }

        data.append('data', JSON.stringify(body))
        const queryId = this.$route.query.id
        const request = !this.isEdit
          ? axios.post('/api/trainingidea/createWorkouts', data)
          : axios.patch(
              `/api/trainingidea/updateWorkouts/${queryId}`,
              data
            )

        await request
          .then(async (result) => {
            let id

            if (this.isEdit) {
              id = queryId
            } else {
              id = result.data._id
            }

            // await axios.post('/api/content/onUpdateContentText', {
            //   id,
            //   timer: this.form.timer,
            // })

            await axios.patch(
              `/api/trainingidea/updateWorkouts/${id}`,
              data
            )

            this.onCancel()
            this.$toast.success('Saved successfully')
            this.$router.go(-1)
            // this.$router.push('/contents/contentlist')
          })
          .catch((err) => {
            this.onCancel()
            this.$toast.error(err.response.data.error.message)
          })
      }
    },
    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios
        .get(`api/trainingidea/getWorkoutsById/${id}`)
        .then((res) => {
          this.form = { ...this.form, ...res.data }
          
          let getTimer = res.data.timer
          const splitTimer = getTimer.split(":")
          this.form.timerMin = splitTimer[0]
          this.form.timerSec = splitTimer[1]

          this.form.inputtag = res.data.hashtag
          this.isEdit = true

          var file = { size: 123, name: 'Icon', type: 'image/png' }
          if (this.form.img_url) {
            var url = `${this.baseImageUrl}/${this.form.img_url}`
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          }

          var fileHeigth = { size: 123, name: 'IconHeigth', type: 'image/png' }
          if (this.form.img_heigth_url) {
            var urlHeigth = `${this.baseImageHeigthUrl}/${this.form.img_heigth_url}`
            this.$refs.myVueDropzoneheigth.manuallyAddFile(
              fileHeigth,
              urlHeigth
            )
          }
        })
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)

      await axios
        .post('api/content/ckupload_images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((result) => {
          let url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    backRoute() {
      this.$router.go(-1)
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0" v-if="this.isEdit">Edit Workouts</h4>
        <h4 class="mb-1 mt-0" v-if="!this.isEdit">Create Workouts</h4>
      </div>
      <div class="row mt-2" style="height: 100%; width: 100%">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.content_title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('content_title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Timer</label>
                      <div class="w-100 d-flex">
                        <b-form-select v-model="form.timerMin" :options="minutes" class="w-25"></b-form-select>
                        <span class="font-weight-bold align-self-center mx-2">:</span>
                        <b-form-select v-model="form.timerSec" :options="second" class="w-25"></b-form-select>                        
                      </div>
                      <!-- <b-form-input
                        id="contentText"
                        v-model="form.timer"
                        name="contentText"
                        type="time"
                        :state="validateState('timer')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input> -->
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>
                        <div
                          v-if="
                            this.$route.query.cat_id ==
                              '616fa0ff97188b0031f44cac' ||
                            this.$route.query.categoryID ==
                              '616fa0ff97188b0031f44cac'
                          "
                          >Image
                          <small class="color-text-small"
                            >Width 1920 x Height 1080. Just only one.</small
                          ></div
                        ><div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fa4c197188b0031f44cad' ||
                            this.$route.query.categoryID ==
                              '616fa4c197188b0031f44cad'
                          "
                        >
                          Image
                          <small class="color-text-small"
                            >Width 1080 x Height 608. Just only one.</small
                          ></div
                        >
                        <div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fa50397188b0031f44cb1' ||
                            this.$route.query.categoryID ==
                              '616fa50397188b0031f44cb1'
                          "
                          >Image
                          <small class="color-text-small"
                            >Width 1080 x Height 1080. Just only one.</small
                          ></div
                        >
                        <div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fa56397188b0031f44cb8' ||
                            this.$route.query.categoryID ==
                              '616fa56397188b0031f44cb8'
                          "
                          >Image
                          <small class="color-text-small"
                            >Width 1080 x Height 608. Just only one.</small
                          ></div
                        >
                        <div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fe39f97188b0031f44d28' ||
                            this.$route.query.categoryID ==
                              '616fe39f97188b0031f44d28'
                          "
                        >
                          Image
                          <small class="color-text-small"
                            >Width 1080 x Height 1080. Just only one.</small
                          ></div
                        >
                        <div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fa51397188b0031f44cb3' ||
                            this.$route.query.categoryID ==
                              '616fa51397188b0031f44cb3'
                          "
                        >
                          Image
                          <small class="color-text-small"
                            >Width 1080 x Height 608. Just only one.</small
                          ></div
                        >
                        <div
                          v-else-if="
                            this.$route.query.cat_id ==
                              '616fa55997188b0031f44cb7' ||
                            this.$route.query.categoryID ==
                              '616fa55997188b0031f44cb7'
                          "
                        >
                          Image
                          <small class="color-text-small"
                            >Width 1080 x Height 608. Just only one.</small
                          ></div
                        >
                        <div v-else
                          >Image
                          <small class="color-text-small"
                            >Width 1080 x Height 1080. Just only one.</small
                          ></div
                        >
                      </label>

                      <vue-dropzone
                        id="form-image"
                        ref="myVueDropzone"
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :state="validateState('image')"
                        aria-describedby="input-3-live-feedback"
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                      ></vue-dropzone>
                      <b-form-invalid-feedback id="input-3-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <div
                      v-if="
                        this.$route.query.cat_id ==
                          '616fa4c197188b0031f44cad' ||
                        this.$route.query.categoryID ==
                          '616fa4c197188b0031f44cad'
                      "
                    >
                      <b-form-group v-if="!form.is_video" label-cols-lg="0">
                        <label>
                          Image
                          <small class="color-text-small"
                            >Width 1080 x Height 1350. Just only one.</small
                          >
                        </label>

                        <vue-dropzone
                          id="form-image-heigth"
                          ref="myVueDropzoneheigth"
                          accepted-files="image/*"
                          :options="dropzoneOptions"
                          :state="validateState('imageHeigth')"
                          aria-describedby="input-3-live-feedback"
                          @vdropzone-file-added="vfileAdded_high"
                          @vdropzone-file-added-manually="
                            vfilemanuallyAdded_high
                          "
                        ></vue-dropzone>
                        <b-form-invalid-feedback id="input-3-live-feedback"
                          >This is a required field</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </div>

                    <!-- <b-form-group label-cols-lg="2" label="Hashtag :">
                      <div>
                        <multiselect
                          v-model="form.inputtag"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search"
                          label="tag_name"
                          track-by="_id"
                          :options="options"
                          :multiple="true"
                        ></multiselect>
                      </div>
                    </b-form-group> -->

                    <b-form-group label-cols-lg="2" label="Status :">
                      <div>
                        <b-form-select
                          v-model="form.status"
                          :options="optionsStatus"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>
                    <b-form-group label-cols-lg="2" label="HighLight :">
                      <b-form-checkbox
                        switch
                        class="switchcss"
                        v-model="form.feat"
                        @click.native="handleClickfeatContent(form)"
                      >
                      </b-form-checkbox>
                    </b-form-group>
                    <!-- <b-form-group label-cols-lg="2" label="Type :">
                      <div>
                        <b-form-select
                          v-model="form.is_paid"
                          :options="optionsType"
                        ></b-form-select>
                      </div>
                    </b-form-group> -->
                    <div v-if="!isEdit">
                      <b-form-group label-cols-lg="0">
                        <b-container class="bv-example-row">
                          <b-row>
                            <label style="color: #4b4b5a; font-weight: 400"
                              >Send Notification *:</label
                            >

                            <b-form-checkbox
                              v-model="form.is_send"
                              class="ml-2"
                            ></b-form-checkbox>
                          </b-row>
                        </b-container>
                      </b-form-group>

                      <div v-if="form.is_send">
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Title:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_title"
                              placeholder="Enter Notification Title"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Text:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_text"
                              placeholder="Enter Notification Text"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group>
                      <!-- <router-link to="/contents/contentlist"> -->
                      <button
                        type="button"
                        class="btn btn-danger float-right ml-2"
                        @click="backRoute()"
                        >Cancel</button
                      >
                      <!-- </router-link> -->
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}

.switchcss {
  padding-top: 8px;
}
</style>
